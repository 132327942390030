<template>
  <v-container fluid>
    <v-img
      contain
      max-width="1200"
      src="../assets/mapArt/Tomatos.jpg"
      style="margin: auto; display: block;"
    ></v-img>
    <v-row
      justify="center"
      align="center"
      style="padding-top:50pt; text-align: center; font-size: 0.75rem;"
    >
      <div style="max-width:320pt; padding-left: 4pt; padding-right: 4pt">
        I was chatting with a friend about food waste at the pub, then I decided
        to do some follow up research. The intent was to code this up in
        something fancy and interactive, but sometimes the pencil crayon is more
        fun than the keyboard.
        <br />
        <!-- (TODO: update old links to sources. Based on 2016 numbers estimated from a  -->
        <br />
        <br />
        Colored pencil on graph paper
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
